<template>
  <div>
    <!-- <div style="text-align: right;margin: 8px 0 16px;">
      <a-input-search
        placeholder="请输入关键词"
        @search="search"
        style="width: 300px;"
      ></a-input-search>
    </div> -->
    <a-table
      class="custom-table"
      row-key="name"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :scroll="{ x: scrollX }"
      :total="total"
      style="margin-bottom: 16px"
      :pagination="false"
    >
      <template slot="name" slot-scope="text">
        <a-tooltip :title="text">{{ text }}</a-tooltip>
      </template>
      <template slot="status" slot-scope="text">
        <source-status-tag
          :name="getStatus(text).name"
          :status="getStatus(text).status"
        ></source-status-tag>
      </template>
      <template slot="cpu" slot-scope="text, record">
        <span v-if="text">{{ (text * 1).toFixed(0) }} %</span>
        <span v-else>0 %</span>
        <a
          v-if="record"
          @click="displayChart(record, 'cpu')"
          style="margin-left: 1px; font-size: 12px"
          ><a-icon type="area-chart"
        /></a>
      </template>
      <template slot="connect" slot-scope="text, record">
        {{ (text * 1).toFixed(0) }}
        <a
          v-if="record"
          @click="displayChart(record, 'connect')"
          style="margin-left: 1px; font-size: 12px"
          ><a-icon type="area-chart"
        /></a>
      </template>
      <template slot="memory" slot-scope="text, record">
        <span v-if="text">{{ (text * 1).toFixed(0) }}</span>
        <span v-else>0</span>
        <a
          v-if="record"
          @click="displayChart(record, 'memory')"
          style="margin-left: 1px; font-size: 12px"
          ><a-icon type="area-chart"
        /></a>
      </template>
      <template slot="temperature" slot-scope="text">
        <span v-if="text">{{ (text * 1).toFixed(0) }}</span>
        <span v-else>0</span>
      </template>
      <template slot="ethportIn" slot-scope="text">
        <span v-if="text">{{ (text * 1).toFixed(0) | human }}</span>
        <span v-else>0</span>
      </template>
      <template slot="ethportOut" slot-scope="text">
        <span v-if="text">{{ (text * 1).toFixed(0) | human }}</span>
        <span v-else>0</span>
      </template>
      <template slot="airportIn" slot-scope="text">
        <span v-if="text">{{ (text * 1).toFixed(0) | human }}</span>
        <span v-else>0</span>
      </template>
      <template slot="airportOut" slot-scope="text">
        <span v-if="text">{{ (text * 1).toFixed(0) | human }}</span>
        <span v-else>0</span>
      </template>
    </a-table>

    <div style="height: 32px; margin-top: 24px">
      <a-pagination
        :current="fetchParams.page"
        :pageSize="fetchParams.page_size"
        :show-size-changer="false"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="
          page => {
            fetchParams.page = page
            fetch()
          }
        "
        style="float: right"
      ></a-pagination>
    </div>

    <multi-line-chart-modal
      ref="chartModal"
      :history-func="historyFunc"
      :source-id="sourceId"
    ></multi-line-chart-modal>
  </div>
</template>

<script>
import {
  getNetworkDeviceMonitorItemList,
  getNetworkDeviceMonitorHistory
} from '@/api/network-device'
import { humanizeValue } from '@/utils'
import MultiLineChartModal from '@/components/modal/MultiLineChartModal'
import SourceStatusTag from '@/components/tag/MonitorStatusTag'

export default {
  name: 'APTable',
  components: {
    MultiLineChartModal,
    SourceStatusTag
  },
  props: {
    acId: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'address',
          title: 'IP 地址',
          width: 150
        },
        {
          dataIndex: 'status',
          width: 100,
          title: '状态',
          align: 'center',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'temperature',
          width: 100,
          title: '温度 (°C)',
          align: 'center',
          scopedSlots: {
            customRender: 'temperature'
          }
        },
        {
          dataIndex: 'connect',
          width: 108,
          align: 'center',
          title: '终端数量',
          scopedSlots: {
            customRender: 'connect'
          }
        },
        {
          dataIndex: 'cpu',
          width: 138,
          align: 'center',
          title: 'CPU 使用率 (%)',
          scopedSlots: {
            customRender: 'cpu'
          }
        },
        {
          dataIndex: 'memory',
          width: 130,
          align: 'center',
          title: '内存使用率 (%)',
          scopedSlots: {
            customRender: 'memory'
          }
        },
        {
          dataIndex: 'ethport_in',
          width: 148,
          align: 'center',
          title: '有线入流量 (bps)',
          scopedSlots: {
            customRender: 'ethportIn'
          }
        },
        {
          dataIndex: 'ethport_out',
          width: 148,
          align: 'center',
          title: '有线出流量 (bps)',
          scopedSlots: {
            customRender: 'ethportOut'
          }
        },
        {
          dataIndex: 'airport_in',
          width: 148,
          align: 'center',
          title: '无线入流量 (bps)',
          scopedSlots: {
            customRender: 'airportIn'
          }
        },
        {
          dataIndex: 'airport_out',
          width: 148,
          align: 'center',
          title: '无线出流量 (bps)',
          scopedSlots: {
            customRender: 'airportOut'
          }
        }
      ],
      fetchParams: {
        page: 1,
        page_size: 10
      },
      historyItems: {
        cpu: {
          title: 'CPU 使用率'
        },
        memory: {
          title: '内存 使用率'
        },
        connect: {
          title: '终端数量'
        }
      },
      total: 0,
      dataSource: [],
      historyFunc: getNetworkDeviceMonitorHistory,
      sourceId: ''
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      this.dataSource = []
      getNetworkDeviceMonitorItemList(this.acId, {
        name: 'IP 地址',
        page: this.fetchParams.page,
        page_size: this.fetchParams.page_size
      })
        .then(res => {
          const data = res.data.data
          this.total = res.data.total
          if (data.length) {
            data.forEach(item => {
              const obj = {
                name: item.key.replace('ap.address[', '').replace(']', ''),
                status: undefined,
                address: item.value,
                ethport_in: 0,
                ethport_out: 0,
                airport_in: 0,
                airport_out: 0,
                cpu: 0,
                memory: 0,
                connect: 0,
                temperature: 0
              }
              const keys = this.getKeys(obj.name)
              keys.forEach(keyObj => {
                getNetworkDeviceMonitorItemList(this.acId, {
                  key: keyObj.key
                }).then(res1 => {
                  obj[keyObj.dataIndex] = res1.data.data[0].value
                })
              })
              this.dataSource.push(obj)
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getKeys (name) {
      return [
        { key: `ap.status[${name}]`, dataIndex: 'status' },
        { key: `ap.temperature[${name}]`, dataIndex: 'temperature' },
        { key: `ap.connect.count[${name}]`, dataIndex: 'connect' },
        { key: `ap.cpu.util[${name}]`, dataIndex: 'cpu' },
        { key: `ap.memory.util[${name}]`, dataIndex: 'memory' },
        { key: `ap.ethport.traffic.in[${name}]`, dataIndex: 'ethport_in' },
        { key: `ap.ethport.traffic.out[${name}]`, dataIndex: 'ethport_out' },
        { key: `ap.airport.traffic.in[${name}]`, dataIndex: 'airport_in' },
        { key: `ap.airport.traffic.out[${name}]`, dataIndex: 'airport_out' }
      ]
    },
    displayChart (record, type) {
      this.sourceId = this.acId
      this.$refs.chartModal.show({
        title: `${this.historyItems[type].title}（${record.name}）`,
        key: this.getKeys(record.name).find(item => item.dataIndex === type).key
      })
    },
    getStatus (status) {
      if (status) {
        if (status * 1 === 8 || status * 1 === 11) return { name: '正常', status: 'up' }
        else return { name: '异常', status: 'down' }
      } else return { name: '未知', status: 'unknown' }
    }
  },
  filters: {
    human (value) {
      return humanizeValue(value)
    }
  }
}
</script>
